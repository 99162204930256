/* Image Buttons (completely seperate button style) */
/* Holds helper mixins (extends compass functionality) */
/* Holds reusable objects for global use */
/* =============================================================================
 *   Navigation
 * ========================================================================== */
/* Tiny Menu */
/* =============================================================================
 *   Box Styles
 * ========================================================================== */
/* =============================================================================
 *   Messages
 * ========================================================================== */
/* Flyout */
/* Overlay */
/* Cutline */
/* Section Titles */
/* =============================================================================
 *   Sticky Elements
 * ========================================================================== */
.sticky-element {
  position: fixed;
  top: 100px;
  z-index: 99;
  right: auto;
  left: auto; }

/* =============================================================================
 *   Grid (BS based)
 * ========================================================================== */
.row {
  overflow: hidden;
  *overflow: visible;
  *zoom: 1;
  margin-left: -21.5px;
  margin-right: -21.5px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
  position: relative;
  min-height: 1px;
  padding-left: 21.5px;
  padding-right: 21.5px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-1 {
  width: 8.33333%; }

.col-xs-2 {
  width: 16.66667%; }

.col-xs-3 {
  width: 25%; }

.col-xs-4 {
  width: 33.33333%; }

.col-xs-5 {
  width: 41.66667%; }

.col-xs-6 {
  width: 50%; }

.col-xs-7 {
  width: 58.33333%; }

.col-xs-8 {
  width: 66.66667%; }

.col-xs-9 {
  width: 75%; }

.col-xs-10 {
  width: 83.33333%; }

.col-xs-11 {
  width: 91.66667%; }

.col-xs-12 {
  width: 100%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-pull-1 {
  right: 8.33333%; }

.col-xs-pull-2 {
  right: 16.66667%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-4 {
  right: 33.33333%; }

.col-xs-pull-5 {
  right: 41.66667%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-7 {
  right: 58.33333%; }

.col-xs-pull-8 {
  right: 66.66667%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-10 {
  right: 83.33333%; }

.col-xs-pull-11 {
  right: 91.66667%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-push-1 {
  left: 8.33333%; }

.col-xs-push-2 {
  left: 16.66667%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-4 {
  left: 33.33333%; }

.col-xs-push-5 {
  left: 41.66667%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-7 {
  left: 58.33333%; }

.col-xs-push-8 {
  left: 66.66667%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-10 {
  left: 83.33333%; }

.col-xs-push-11 {
  left: 91.66667%; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-offset-0 {
  margin-left: 0%; }

.col-xs-offset-1 {
  margin-left: 8.33333%; }

.col-xs-offset-2 {
  margin-left: 16.66667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.33333%; }

.col-xs-offset-5 {
  margin-left: 41.66667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.33333%; }

.col-xs-offset-8 {
  margin-left: 66.66667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.33333%; }

.col-xs-offset-11 {
  margin-left: 91.66667%; }

.col-xs-offset-12 {
  margin-left: 100%; }

@media (min-width: 544px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-1 {
    width: 8.33333%; }
  .col-sm-2 {
    width: 16.66667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.33333%; }
  .col-sm-5 {
    width: 41.66667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.33333%; }
  .col-sm-8 {
    width: 66.66667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.33333%; }
  .col-sm-11 {
    width: 91.66667%; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-pull-1 {
    right: 8.33333%; }
  .col-sm-pull-2 {
    right: 16.66667%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-4 {
    right: 33.33333%; }
  .col-sm-pull-5 {
    right: 41.66667%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-7 {
    right: 58.33333%; }
  .col-sm-pull-8 {
    right: 66.66667%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-10 {
    right: 83.33333%; }
  .col-sm-pull-11 {
    right: 91.66667%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-push-1 {
    left: 8.33333%; }
  .col-sm-push-2 {
    left: 16.66667%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-4 {
    left: 33.33333%; }
  .col-sm-push-5 {
    left: 41.66667%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-7 {
    left: 58.33333%; }
  .col-sm-push-8 {
    left: 66.66667%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-10 {
    left: 83.33333%; }
  .col-sm-push-11 {
    left: 91.66667%; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-offset-0 {
    margin-left: 0%; }
  .col-sm-offset-1 {
    margin-left: 8.33333%; }
  .col-sm-offset-2 {
    margin-left: 16.66667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.33333%; }
  .col-sm-offset-5 {
    margin-left: 41.66667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.33333%; }
  .col-sm-offset-8 {
    margin-left: 66.66667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.33333%; }
  .col-sm-offset-11 {
    margin-left: 91.66667%; }
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 768px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-1 {
    width: 8.33333%; }
  .col-md-2 {
    width: 16.66667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.33333%; }
  .col-md-5 {
    width: 41.66667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.33333%; }
  .col-md-8 {
    width: 66.66667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.33333%; }
  .col-md-11 {
    width: 91.66667%; }
  .col-md-12 {
    width: 100%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-pull-1 {
    right: 8.33333%; }
  .col-md-pull-2 {
    right: 16.66667%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-4 {
    right: 33.33333%; }
  .col-md-pull-5 {
    right: 41.66667%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-7 {
    right: 58.33333%; }
  .col-md-pull-8 {
    right: 66.66667%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-10 {
    right: 83.33333%; }
  .col-md-pull-11 {
    right: 91.66667%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-push-1 {
    left: 8.33333%; }
  .col-md-push-2 {
    left: 16.66667%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-4 {
    left: 33.33333%; }
  .col-md-push-5 {
    left: 41.66667%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-7 {
    left: 58.33333%; }
  .col-md-push-8 {
    left: 66.66667%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-10 {
    left: 83.33333%; }
  .col-md-push-11 {
    left: 91.66667%; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-offset-0 {
    margin-left: 0%; }
  .col-md-offset-1 {
    margin-left: 8.33333%; }
  .col-md-offset-2 {
    margin-left: 16.66667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.33333%; }
  .col-md-offset-5 {
    margin-left: 41.66667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.33333%; }
  .col-md-offset-8 {
    margin-left: 66.66667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.33333%; }
  .col-md-offset-11 {
    margin-left: 91.66667%; }
  .col-md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-1 {
    width: 8.33333%; }
  .col-lg-2 {
    width: 16.66667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.33333%; }
  .col-lg-5 {
    width: 41.66667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.33333%; }
  .col-lg-8 {
    width: 66.66667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.33333%; }
  .col-lg-11 {
    width: 91.66667%; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-pull-1 {
    right: 8.33333%; }
  .col-lg-pull-2 {
    right: 16.66667%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-4 {
    right: 33.33333%; }
  .col-lg-pull-5 {
    right: 41.66667%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-7 {
    right: 58.33333%; }
  .col-lg-pull-8 {
    right: 66.66667%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-10 {
    right: 83.33333%; }
  .col-lg-pull-11 {
    right: 91.66667%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-push-1 {
    left: 8.33333%; }
  .col-lg-push-2 {
    left: 16.66667%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-4 {
    left: 33.33333%; }
  .col-lg-push-5 {
    left: 41.66667%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-7 {
    left: 58.33333%; }
  .col-lg-push-8 {
    left: 66.66667%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-10 {
    left: 83.33333%; }
  .col-lg-push-11 {
    left: 91.66667%; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-offset-0 {
    margin-left: 0%; }
  .col-lg-offset-1 {
    margin-left: 8.33333%; }
  .col-lg-offset-2 {
    margin-left: 16.66667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.33333%; }
  .col-lg-offset-5 {
    margin-left: 41.66667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.33333%; }
  .col-lg-offset-8 {
    margin-left: 66.66667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.33333%; }
  .col-lg-offset-11 {
    margin-left: 91.66667%; }
  .col-lg-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1140px) {
  .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    float: left; }
  .col-xl-1 {
    width: 8.33333%; }
  .col-xl-2 {
    width: 16.66667%; }
  .col-xl-3 {
    width: 25%; }
  .col-xl-4 {
    width: 33.33333%; }
  .col-xl-5 {
    width: 41.66667%; }
  .col-xl-6 {
    width: 50%; }
  .col-xl-7 {
    width: 58.33333%; }
  .col-xl-8 {
    width: 66.66667%; }
  .col-xl-9 {
    width: 75%; }
  .col-xl-10 {
    width: 83.33333%; }
  .col-xl-11 {
    width: 91.66667%; }
  .col-xl-12 {
    width: 100%; }
  .col-xl-pull-0 {
    right: auto; }
  .col-xl-pull-1 {
    right: 8.33333%; }
  .col-xl-pull-2 {
    right: 16.66667%; }
  .col-xl-pull-3 {
    right: 25%; }
  .col-xl-pull-4 {
    right: 33.33333%; }
  .col-xl-pull-5 {
    right: 41.66667%; }
  .col-xl-pull-6 {
    right: 50%; }
  .col-xl-pull-7 {
    right: 58.33333%; }
  .col-xl-pull-8 {
    right: 66.66667%; }
  .col-xl-pull-9 {
    right: 75%; }
  .col-xl-pull-10 {
    right: 83.33333%; }
  .col-xl-pull-11 {
    right: 91.66667%; }
  .col-xl-pull-12 {
    right: 100%; }
  .col-xl-push-0 {
    left: auto; }
  .col-xl-push-1 {
    left: 8.33333%; }
  .col-xl-push-2 {
    left: 16.66667%; }
  .col-xl-push-3 {
    left: 25%; }
  .col-xl-push-4 {
    left: 33.33333%; }
  .col-xl-push-5 {
    left: 41.66667%; }
  .col-xl-push-6 {
    left: 50%; }
  .col-xl-push-7 {
    left: 58.33333%; }
  .col-xl-push-8 {
    left: 66.66667%; }
  .col-xl-push-9 {
    left: 75%; }
  .col-xl-push-10 {
    left: 83.33333%; }
  .col-xl-push-11 {
    left: 91.66667%; }
  .col-xl-push-12 {
    left: 100%; }
  .col-xl-offset-0 {
    margin-left: 0%; }
  .col-xl-offset-1 {
    margin-left: 8.33333%; }
  .col-xl-offset-2 {
    margin-left: 16.66667%; }
  .col-xl-offset-3 {
    margin-left: 25%; }
  .col-xl-offset-4 {
    margin-left: 33.33333%; }
  .col-xl-offset-5 {
    margin-left: 41.66667%; }
  .col-xl-offset-6 {
    margin-left: 50%; }
  .col-xl-offset-7 {
    margin-left: 58.33333%; }
  .col-xl-offset-8 {
    margin-left: 66.66667%; }
  .col-xl-offset-9 {
    margin-left: 75%; }
  .col-xl-offset-10 {
    margin-left: 83.33333%; }
  .col-xl-offset-11 {
    margin-left: 91.66667%; }
  .col-xl-offset-12 {
    margin-left: 100%; } }

/* =============================================================================
 *   Default template containers
 * ========================================================================== */
.page {
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px; }
  @media only screen and (min-width: 768px) {
    .page {
      padding-left: 30px;
      padding-right: 30px; } }

.page-columns-outer {
  padding-top: 25px; }

@media (min-width: 992px) {
  .page-columns {
    display: flex; } }

.page-node-edit .page,
.page-node-add .page {
  max-width: 1400px; }

.content-column {
  padding-bottom: 25px;
  max-width: 100%;
  flex-grow: 1; }

.page-columns:not(.row) .content-column {
  width: 100%; }

/* Footer */
@media (min-width: 768px) {
  .region-footer > .region-inner {
    overflow: hidden;
    *overflow: visible;
    *zoom: 1;
    margin-left: -21.5px;
    margin-right: -21.5px;
    display: flex; } }

@media (min-width: 768px) {
  .region-footer > .region-inner .block {
    position: relative;
    float: left;
    min-height: 1px;
    padding-left: 21.5px;
    padding-right: 21.5px;
    width: 33.33333%; } }

/* =============================================================================
 *   Full width definitions
 * ========================================================================== */
.full-width-section {
  width: auto;
  background-color: #ebebeb; }

.full-width-section__inner {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  background-color: white; }

.full-width-image-section {
  max-width: 2280px;
  margin-left: auto;
  margin-right: auto; }
  .full-width-image-section img {
    max-width: 100%;
    width: auto; }
  .full-width-image-section > .inner {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto; }

/* =============================================================================
 *   Grid
 * ========================================================================== */
/* Fix Bootstrap .row clearfix behavoir */
.row.clearfix,
.region-inner.clearfix {
  overflow: visible; }

/* Quickfix: We need to comensate the negative margin on the group title */
.item-list[class*="layout-"] > h3 {
  margin-left: 43px; }

/* Tile Grid */
/* =============================================================================
 *   Some "Layout helper classes"
 * ========================================================================== */
.float-left,
.float-right {
  text-align: center;
  display: block;
  margin: 0 auto 25px auto; }

.clear {
  clear: both; }

.clear-left {
  clear: left; }

.clear-right {
  clear: right; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

@-ms-viewport {
  width: device-width; }

.visible-xs {
  display: none !important; }

.visible-sm {
  display: none !important; }

.visible-md {
  display: none !important; }

.visible-lg {
  display: none !important; }

.visible-xlg {
  display: none !important; }

.visible-xxlg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-xlg-block,
.visible-xlg-inline,
.visible-xlg-inline-block,
.visible-xxlg-block,
.visible-xxlg-inline,
.visible-xxlg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table !important; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 544px) and (max-width: 767px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table !important; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; } }

@media (min-width: 544px) and (max-width: 767px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 544px) and (max-width: 767px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 544px) and (max-width: 767px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table !important; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 992px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 992px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 992px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (min-width: 1140px) {
  .visible-xlg {
    display: block !important; }
  table.visible-xlg {
    display: table !important; }
  tr.visible-xlg {
    display: table-row !important; }
  th.visible-xlg,
  td.visible-xlg {
    display: table-cell !important; } }

@media (min-width: 1140px) {
  .visible-xlg-block {
    display: block !important; } }

@media (min-width: 1140px) {
  .visible-xlg-inline {
    display: inline !important; } }

@media (min-width: 1140px) {
  .visible-xlg-inline-block {
    display: inline-block !important; } }

@media (min-width: 1420px) {
  .visible-xxlg {
    display: block !important; }
  table.visible-xxlg {
    display: table !important; }
  tr.visible-xxlg {
    display: table-row !important; }
  th.visible-xxlg,
  td.visible-xxlg {
    display: table-cell !important; } }

@media (min-width: 1420px) {
  .visible-xxlg-block {
    display: block !important; } }

@media (min-width: 1420px) {
  .visible-xxlg-inline {
    display: inline !important; } }

@media (min-width: 1420px) {
  .visible-xxlg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 544px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1139px) {
  .hidden-lg {
    display: none !important; } }

@media (min-width: 1140px) and (max-width: 1419px) {
  .hidden-xlg {
    display: none !important; } }

@media (min-width: 1420px) {
  .hidden-xxlg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table !important; }
  tr.visible-print {
    display: table-row !important; }
  th.visible-print,
  td.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }
  @media print {
    .visible-print-block {
      display: block !important; } }

.visible-print-inline {
  display: none !important; }
  @media print {
    .visible-print-inline {
      display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }
  @media print {
    .visible-print-inline-block {
      display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

@media only screen and (min-width: 768px) {
  /* Content */
  /* -- Two Cols */
  .col-left {
    box-sizing: border-box;
    float: left;
    width: 50%;
    padding-right: 21.5px; }
  .col-right {
    box-sizing: border-box;
    float: left;
    width: 50%;
    padding-left: 21.5px; }
  /* Some "Layout helper classes" */
  body .float-left,
  body .floatleft,
  body .left,
  body .float-left {
    text-align: center;
    display: block;
    margin: 0 auto 25px auto; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    body .float-left,
    body .floatleft,
    body .left,
    body .float-left {
      display: block;
      float: left;
      margin: 5px 43px 25px 0; } }

@media only screen and (min-width: 768px) {
  body .float-right,
  body .floatright,
  body .right,
  body .float-right {
    text-align: center;
    display: block;
    margin: 0 auto 25px auto; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    body .float-right,
    body .floatright,
    body .right,
    body .float-right {
      display: block;
      float: right;
      margin: 5px 0 25px 43px; } }

/* =============================================================================
 *   Drupal specific (default module styles)
 * ========================================================================== */
@media only screen and (min-width: 768px) {
  /* ========== Panels ========= */
  .region-two-cols-embed-embed {
    float: right;
    margin: 0 0 25px 43px;
    max-width: 351px; }
  /* ========== Views ========== */
  /* -- Image */
  .view .item-list li.views-row .view-image {
    float: left;
    margin: 5px 43px 3px 0; }
  /* -- Views Row with Image Column */
  /* -- -- Links anliegend */
  .view .item-list li.image-column > .inner {
    padding-left: 163px; }
  .view .item-list li.image-column > .inner .view-image {
    width: 120px;
    overflow: hidden;
    margin-left: -163px;
    margin-right: 0;
    padding: 0; }
  /* -- -- Links Abstand (z. B. bei .box-style) */
  .view .item-list li.image-column.left-space > .inner {
    padding-left: 178px; }
  .view .item-list li.image-column.left-space > .inner .view-image {
    width: 120px;
    overflow: hidden;
    margin-left: -155.5px;
    margin-right: 0;
    padding: 0; } }
