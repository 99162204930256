
// _base.scss imports _custom.scss (variables and mixins) and other partials.
// This file defines Grid & Layout for small devices eg. Smartphones (mobile first).
@import "base";

/* =============================================================================
 *   Sticky Elements
 * ========================================================================== */
.sticky-element{
  position:fixed;
  top:100px;
  z-index:99;
  right:auto;
  left:auto;
  &--left{

  }
  &--right{
    // right:0;
  }
}

/* =============================================================================
 *   Grid (BS based)
 * ========================================================================== */

.row{
  @include make-row();
}

@include make-grid-columns();

/* =============================================================================
 *   Default template containers
 * ========================================================================== */

.page{
  @if($full_width_page == false){
    max-width:$page_max_width + ($page_outer_grid * 2);
    padding-left:$page_outer_grid_mobile;
    padding-right:$page_outer_grid_mobile;
    padding-bottom:$page_outer_grid;
    @media #{$medium-up}{
      padding-left:$page_outer_grid;
      padding-right:$page_outer_grid;
    }
  }
}
.page-columns-outer{
  padding-top:$grid_vertical;
}

.page-columns{
  @include media-breakpoint-up(lg){
    display:flex;
  }
}

// More Max-width for node edit pages
.page-node-edit,
.page-node-add{
  .page{
    @if($full_width_page == false){
      max-width:1400px;
    }
  }
}

// Tile overview pages have a higher max-width
.front{
  .page,
  .page-header > .inner{
    // currently not in use
    // max-width:$page_max_width_tile_pages;
  }
}

.content-column{
  padding-bottom:$grid_vertical;
  max-width:100%; // overflow Bugfix (eg.: Scrollable Tabs - not the tab container itself is getting scrollable, the whole page will be scrollable)
  flex-grow:1;
}
.page-columns:not(.row){
  .content-column{
    // ie11 fix:
    width:100%;
  }
}


/* Footer */
// Better use mini-panels to group those blocks.
@if $footer_mini_panel != true{
  @if $show_footer_menu_vertical == true{
    .region-footer > .region-inner{
      @include media-breakpoint-up(md){
        @include make-row();
        // PE: Flexbox same height
        display:flex;
      }
      .block{
        @include media-breakpoint-up(md){
          @include make-col();
          @include make-col-span(4);
        }
      }
    }
  }@else{
    .region-footer > .region-inner {
      @include make-row();
      .block{
        @include make-col();
        @include make-col-span(4);
        &.block-menu-menu-footer-menu{
          clear:both;
          float:none;
          width:auto;
          margin-top:$space_thin_v;
        }
      }
    }
  }
}


/* =============================================================================
 *   Full width definitions
 * ========================================================================== */

// Full width sections
.full-width-section{
  width:auto; // we need an attribute to use extend..
  background-color:$full_width_bg_color;
}
.full-width-section__inner{
  max-width:$page_max_width;
  margin-left:auto;
  margin-right:auto;
  padding-left:$page_outer_grid;
  padding-right:$page_outer_grid;

  background-color:$page_background;
}

.full-width-image-section{
  // Only used for detail view of articles
  // Put the image direclty inside this wrapper!
  max-width:$page_max_width * 2; // we dont want this infinite width
  margin-left:auto;
  margin-right:auto;
  img{
    max-width:100%;
    width:auto;
  }
  > .inner{
    // Contents above the image - dont put the image inside this!
    max-width:$page_max_width;
    margin-left:auto;
    margin-right:auto;
  }
}

// Define full width Wrappers
@if($full_width_page == true){
  .page > .region,
  .page-header,
  .page-columns-outer,
  .page-footer{
    @extend .full-width-section;
  }
  .page > .region > .region-inner,
  .navigation-bar-wrapper > .inner,
  .page-header > .inner,
  .page-columns,
  .page-footer > .inner{
    @extend .full-width-section__inner;
  }
}


/* =============================================================================
 *   Grid
 * ========================================================================== */

// This file defines default Grid & Layouts (for page layout itself, for panels & custom marked up layouts)
// The needed Bootstrap parts are included inside the include.bootstrap.scss - we just include the things we really need
// Normally you don´t have to touch this file. Everything goes by _configuration.scss

/* Fix Bootstrap .row clearfix behavoir */
.row,
.region-inner{
  &.clearfix{
    overflow:visible;
  }
}

/* Quickfix: We need to comensate the negative margin on the group title */
.item-list[class*="layout-"] > h3{
  margin-left:$grid;
}

/* Tile Grid */
@if $layout_tile_grid_active == true{
  .tile-grid{
    margin:0 auto;
    max-width:$tileWidth;
    padding-top:5px; // Otherwise tile clip at the top will be cut off
    &__item{
      width:$tileWidth; // attention! if you change this value, you have to change it in the layout.custom.js too! Percentage width does not work good with masonry.
    }
    @media #{$medium-up}{
      // 2 Tiles in a "row"
      max-width:($tileWidth * 2) + $tileGridSpace;
    }
    @media #{$large-up}{
      // 3 Tiles in a "row"
      max-width:($tileWidth * 3) + ($tileGridSpace * 2);
    }
    @media #{$xlarge-up}{
      // 4 Tiles in a "row"
      max-width:($tileWidth * 4) + ($tileGridSpace * 3);
    }
    @media #{$xxlarge-up}{
      // 5 Tiles in a "row"
      max-width:($tileWidth * 5) + ($tileGridSpace * 4);
    }
  }
}


/* =============================================================================
 *   Some "Layout helper classes"
 * ========================================================================== */

.float-left,
.float-right{
  text-align:center;
  display:block;
  margin:0 auto $grid_vertical auto;
}
.clear{ clear:both; }
.clear-left{ clear:left; }
.clear-right{ clear:right; }

.hide{ display:none !important; }
.show{ display:block !important; }

// Responsive Helpers
@import 'components/responsive-utilities';

// Enough Width (means: Full content width, or the moment when the sidebar is already moved to bottom, so the content is wider again)
@media #{$enough-width}{
  @mixin mobileImage{
    text-align:center;
    display:block;
    margin:0 auto $grid_vertical auto;
  }

  /* Content */
  /* -- Two Cols */
  .col-left{ box-sizing:border-box; float:left; width:50%; padding-right:$grid / 2; }
  .col-right{ box-sizing:border-box; float:left; width:50%;  padding-left:$grid / 2; }

  /* Some "Layout helper classes" */
    // Using body to make it more important then global.layout.css definitions which is implemented later in cause of technical trouble
  body .float-left,
  body .floatleft,
  body .left,
  body .float-left{
    @include mobileImage();
    @media #{$enough-width}{
      display:block;
      float:left;
      margin:5px $grid $grid_vertical 0;
    }
  }
  body .float-right,
  body .floatright,
  body .right,
  body .float-right{
    @include mobileImage();
    @media #{$enough-width}{
      display:block;
      float:right;
      margin:5px 0 $grid_vertical $grid;
    }
  }

}

/* =============================================================================
 *   Drupal specific (default module styles)
 * ========================================================================== */

@media #{$enough-width}{

  /* ========== Panels ========= */
  .region-two-cols-embed-embed{
    // todo: take a look if this layout still exist.. if it does, try to use bootstrap classes instead of this declaration
    float:right;
    margin:0 0 $grid_vertical $grid;
    max-width:351px; // tile width
  }

  /* ========== Views ========== */
  /* -- Image */
  .view .item-list li.views-row .view-image{ float:left; margin: 5px $grid 3px 0; }
  /* -- Views Row with Image Column */
  /* -- -- Links anliegend */
  .view .item-list li.image-column > .inner{ padding-left: $view_image_width + $grid; }
  .view .item-list li.image-column > .inner .view-image{ width: $view_image_width; overflow:hidden; margin-left: - ($view_image_width + $grid); margin-right:0; padding:0; }
  /* -- -- Links Abstand (z. B. bei .box-style) */
  .view .item-list li.image-column.left-space > .inner{ padding-left: $view_image_width + $grid + $thin_grid; }
  .view .item-list li.image-column.left-space > .inner .view-image{ width: $view_image_width; overflow:hidden; margin-left: - ($view_image_width + $grid - ($thin_grid / 2)); margin-right:0; padding:0; }

}
